import React, { useEffect, useRef, useCallback, useState } from "react";

import { RangeSlider, Button, DetailModal } from "components";
import { useCarousel, usePreview } from "hooks";
import { useDownloadFile } from "services";
import {
  LeftIconImg,
  RightIconImg,
  RotationIconImg,
  ZoomInIconImg,
  ZoomOutIconImg,
  ZoomInDisableIconImg,
  ZoomOutDisableIconImg,
  DownloadIconImg,
  AlternateImg,
} from "assets";
import * as S from "./PreviewModal.styled";

interface PreviewModalProps {
  files: {
    images: any[]; // TODO: any 타입 고치기
  };
  hasDownload?: boolean;
  selectedImgIndex: number;
  handleParentModalInvisible?: () => void;
  handleParentModalVisible?: () => void;
  handleModalClose: () => void;
}

const PreviewModal = React.forwardRef(
  (
    {
      handleModalClose,
      files: { images },
      hasDownload = true,
      selectedImgIndex,
      handleParentModalInvisible,
      handleParentModalVisible,
    }: PreviewModalProps,
    ref: React.ForwardedRef<HTMLDialogElement>,
  ) => {
    const isMounted = useRef(false);

    const [isImgLoadDone, setIsImgLoadDone] = useState(false);
    const [normalFiles, setNormalFiles] = useState<boolean[]>(
      new Array(images.length).fill(true),
    );

    const { currentSlide, handleNextSlide, handlePrevSlide } = useCarousel(
      images.length,
      selectedImgIndex,
    );

    const { refetch: refetchDownloadFile } = useDownloadFile(
      images[currentSlide],
      { enabled: false },
    );

    const {
      imagesRef,
      scale,
      handleClickRotate,
      handleClickZoomOut,
      handleClickZoomIn,
      handleChangeScale,
      onMouseDown,
    } = usePreview(currentSlide);

    const handleClose = useCallback(() => {
      handleModalClose();
      typeof handleParentModalVisible === "function" &&
        handleParentModalVisible();
    }, []);

    const handleDownload = () => {
      refetchDownloadFile();
    };

    const handleErrorImg =
      (i: number) => (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        (e.target as HTMLImageElement).src = AlternateImg;
        const newNormalFiles = [...normalFiles];
        newNormalFiles[i] = false;
        setNormalFiles(newNormalFiles);
      };

    const handleLoadImg = () => {
      setIsImgLoadDone(true);
    };

    useEffect(() => {
      if (isMounted.current) {
        typeof handleParentModalInvisible === "function" &&
          handleParentModalInvisible();
      }

      isMounted.current = true;
    }, []);

    return (
      <DetailModal
        ref={ref}
        css={S.detailModal}
        title="첨부이미지"
        closeFn={handleClose}
      >
        {images.length > 0 && (
          <>
            <S.UtilContainer>
              <span>
                {`사진파일(${currentSlide + 1}/${images.length}) : ${
                  images[currentSlide].label
                }`}
              </span>
              <S.ZoomWrapper>
                <button
                  type="button"
                  aria-label="축소 하기"
                  disabled={scale < 1.01}
                  onClick={handleClickZoomOut}
                >
                  {scale < 1.01 ? (
                    <ZoomOutDisableIconImg />
                  ) : (
                    <ZoomOutIconImg />
                  )}
                </button>
                <RangeSlider
                  min="1.0"
                  value={scale}
                  max="2.0"
                  step="0.1"
                  handleChangeRange={handleChangeScale}
                />
                <button
                  type="button"
                  aria-label="확대 하기"
                  disabled={scale === 2.01}
                  onClick={handleClickZoomIn}
                >
                  {scale === 2.01 ? (
                    <ZoomInDisableIconImg />
                  ) : (
                    <ZoomInIconImg />
                  )}
                </button>
              </S.ZoomWrapper>
              <S.RightWrapper>
                <button
                  type="button"
                  aria-label="회전 하기"
                  onClick={handleClickRotate}
                >
                  <RotationIconImg />
                </button>
                {hasDownload && normalFiles[currentSlide] && isImgLoadDone && (
                  <Button
                    css={S.downloadButton}
                    icon={<DownloadIconImg css={S.downloadIcon} />}
                    variant="ghostPrimary"
                    onClick={handleDownload}
                  >
                    다운로드
                  </Button>
                )}
              </S.RightWrapper>
            </S.UtilContainer>
            <S.ModalContent>
              <S.SlideButton
                type="button"
                aria-label="이전 이미지"
                disabled={currentSlide === 0}
                onClick={handlePrevSlide}
              >
                <LeftIconImg />
              </S.SlideButton>
              <S.SliderRoot>
                <S.SliderWrapper currentSlide={currentSlide}>
                  {images.map(({ presignedUrl, label }, i) => (
                    <S.Slider key={label}>
                      <S.Img
                        ref={(el) => (imagesRef.current[i] = el)}
                        src={presignedUrl}
                        alt={label}
                        isNormalImg={normalFiles[currentSlide]}
                        draggable={false}
                        onLoad={handleLoadImg}
                        onError={handleErrorImg(i)}
                        onMouseDown={
                          normalFiles[currentSlide] ? onMouseDown : undefined
                        }
                      />
                    </S.Slider>
                  ))}
                </S.SliderWrapper>
              </S.SliderRoot>
              <S.SlideButton
                type="button"
                onClick={handleNextSlide}
                disabled={currentSlide === images.length - 1}
                aria-label="다음 이미지"
              >
                <RightIconImg />
              </S.SlideButton>
            </S.ModalContent>
          </>
        )}
        {/* {audio.file && (
          <AudioWrapper>
            <span>음성파일</span>
            <Audio src={audio.presignedUrl} />
            <Button
              icon={<DownloadIconImg css={S.downloadIcon} />}
              variant="ghostPrimary"
              handleDownload={audio}
            >
              다운로드
            </Button>
          </AudioWrapper>
        )} */}
      </DetailModal>
    );
  },
);

PreviewModal.displayName = "PreviewModal";

export default PreviewModal;
