import { getPresignedUrlAPI } from "apis/common";
import type {
  AcceptReservationQueryModel,
  CancelReservationQueryModel,
  ChangeReservationQueryModel,
  CreateReservationQueryModel,
  FinishReservationQueryModel,
  GetReservationDetailServerModel,
  GetReservationsDailyQueryModel,
  GetReservationsDailyServerModel,
  GetReservationsMonthlyQueryModel,
  GetReservationsMonthlyServerModel,
  StartReservationQueryModel,
  UpdateReservationDetailQueryModel,
  UpdateReservationEstimatedTimeQueryModel,
  UpdateReservationMemoQueryModel,
} from "types";
import { ax } from "../axios";

export const getReservationsMonthlyAPI = async (
  req: GetReservationsMonthlyQueryModel,
) => {
  const { data } = await ax.get<GetReservationsMonthlyServerModel>(
    `/reservations/monthly`,
    { params: req.query },
  );

  return data;
};

export const getReservationsDailyAPI = async (
  req: GetReservationsDailyQueryModel,
) => {
  const { data } = await ax.get<GetReservationsDailyServerModel>(
    "/reservations/daily",
    { params: req.query },
  );
  return data;
};

export const getReservationDetailAPI = async (reservationId: string) => {
  const { data } = await ax.get<GetReservationDetailServerModel>(
    `/reservations/${reservationId}`,
  );

  const files = [
    data.external1,
    data.external2,
    data.external3,
    data.internal1,
    data.internal2,
    data.internal3,
    data.dashboard,
    data.audio,
  ];

  const presignedUrls = await Promise.all([
    ...files.map((url) => getPresignedUrlAPI(url)),
  ]);

  const presignedExternals = [
    presignedUrls[0],
    presignedUrls[1],
    presignedUrls[2],
  ].filter((external) => external !== null);
  const presignedInternals = [
    presignedUrls[3],
    presignedUrls[4],
    presignedUrls[5],
  ].filter((internal) => internal !== null);

  const externals = [
    presignedUrls[0] ? data.external1 : "",
    presignedUrls[1] ? data.external2 : "",
    presignedUrls[2] ? data.external3 : "",
  ].filter(Boolean);

  const internals = [
    presignedUrls[3] ? data.internal1 : "",
    presignedUrls[4] ? data.internal2 : "",
    presignedUrls[5] ? data.internal3 : "",
  ].filter(Boolean);

  const newData = {
    ...data,
    hasAttachedFiles: files.filter(Boolean).length > 0,
    externals,
    internals,
    presignedDashboard: presignedUrls[6],
    presignedAudio: presignedUrls[7],
    presignedExternals:
      presignedExternals.length > 0 ? presignedExternals : null,
    presignedInternals:
      presignedInternals.length > 0 ? presignedInternals : null,
  };

  return newData;
};

export const acceptReservationAPI = (req: AcceptReservationQueryModel) => {
  return ax.patch(`/reservations/${req.reservationId}/accept`, req.body);
};

export const changeReservationAPI = (req: ChangeReservationQueryModel) => {
  return ax.patch(`/reservations/${req.reservationId}/update`, req.body);
};

export const cancelReservationAPI = (req: CancelReservationQueryModel) => {
  return ax.patch(`/reservations/${req.reservationId}/cancel`, req.body);
};

export const startReservationAPI = (req: StartReservationQueryModel) => {
  return ax.patch(`/reservations/${req.reservationId}/start`, req.body);
};

export const finishReservationAPI = (req: FinishReservationQueryModel) => {
  return ax.patch(`/reservations/${req.reservationId}/finish`, req.body);
};

export const updateReservationDetailAPI = (
  req: UpdateReservationDetailQueryModel,
) => {
  return ax.put(`/reservations/${req.reservationId}`, req.body);
};

export const createReservationAPI = (req: CreateReservationQueryModel) => {
  return ax.post("/reservations", req.body);
};

export const updateReservationEstimatedTimeAPI = (
  req: UpdateReservationEstimatedTimeQueryModel,
) => {
  return ax.put(`/reservations/${req.reservationId}/estimated`, req.body);
};

export const getReservationExcelAPI = async (
  req: GetReservationsDailyQueryModel,
) => {
  const { data } = await ax.get(`/reservations/excel`, {
    responseType: "arraybuffer",
    params: req.query,
  });

  return data;
};

export const updateReservationMemoAPI = (
  req: UpdateReservationMemoQueryModel,
) => {
  return ax.put(`/reservations/${req.reservationId}/memo`, req.body);
};
