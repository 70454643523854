import axios from "axios";

import { auth } from "utils";
import type {
  FetchBrandServerModel,
  GetMechanicServerModel,
  S3PresignedServerModel,
} from "types";
import { ax, commonAx, BASE_URL } from "../axios";

export const getBrandsAPI = async () => {
  const { data } = await commonAx.get<FetchBrandServerModel>("/brands");

  return data;
};

export const getMechanicsAPI = async () => {
  const { data } = await ax.get<GetMechanicServerModel>("/staffs/mechanics");

  return data;
};

export const getHolidays = async () => {
  const { data } = await commonAx.get("/holidays");

  return data;
};

export const renewAccessTokenAPI = async (refreshToken: string) => {
  const { repairShopId } = auth;

  const res = await axios.post(`${BASE_URL}/repairshops/auth/token`, {
    refreshToken,
    repairShopId,
  });

  return res.data;
};

export const getPresignedUrlAPI = async (key: string | null) => {
  if (!key) {
    return null;
  }

  try {
    const {
      data: { url },
    } = await commonAx.get(`/presigned/${key}`);

    return url;
  } catch (e) {
    return "";
  }
};

export const postPresignedUrlAPI = async ({
  s3Key,
  file,
}: {
  s3Key: string | undefined;
  file: File;
}) => {
  const postPresigned: S3PresignedServerModel = await commonAx.post(
    `/presigned/${s3Key}`,
  );
  const { url, fields } = postPresigned.data;

  const formData = new FormData();
  for (const [key, value] of Object.entries(fields)) {
    formData.append(key, value);
  }

  formData.append("Content-type", file.type);
  formData.append("file", file);

  await axios.post(url, formData);
};

export const getFileAPI = (url: string) => {
  return axios.get(url, { responseType: "blob" });
};

export const downloadFileAPI = async (req: any) => {
  const url = await getPresignedUrlAPI(req);
  const data = await getFileAPI(url);

  return data;
};

export const downloadZipAPI = async (req: any[]) => {
  const zipName = req[0].name.split("-")[0];
  const presignedDatas = await Promise.all(
    req.map(async (data) => await getPresignedUrlAPI(data.file)),
  );

  const presignedUrls = await Promise.all([
    ...presignedDatas.map((res) => getFileAPI(res)),
  ]);

  const files = presignedUrls.map((file: any, idx: any) => ({
    file,
    name: req[idx].name,
    extension: req[idx].extension,
  }));

  return {
    files,
    zipName,
  };
};
