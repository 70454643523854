import { v4 as uuidv4 } from "uuid";
import type { AxiosResponse } from "axios";

import { getPresignedUrlAPI, postPresignedUrlAPI } from "apis";
import { deleteKeyNullInValue } from "utils";
import { REPAIR_PREFIX_KEY } from "assets";
import type {
  KeyOf,
  RepairshopServerModel,
  UpdateRepairshopQueryModel,
} from "types";
import { ax } from "../axios";

export const getRepairshopInfo = async (): Promise<RepairshopServerModel> => {
  const { data } = await ax.get<RepairshopServerModel>("");

  const { thumbnail, extra1, extra2, extra3, extra4 } = data;

  const extraKeyList = [extra1, extra2, extra3, extra4].filter(
    (v) => v != null,
  );

  const extraFileList = await Promise.all(
    extraKeyList.map(async (url) => {
      if (!url) return;
      return await getPresignedUrlAPI(url);
    }),
  );

  const fileUrlList: AxiosResponse<any, unknown>[] = await Promise.all([
    getPresignedUrlAPI(thumbnail),
    ...extraFileList,
  ] as AxiosResponse<string, unknown>[]);

  let newData = { ...data };

  fileUrlList.filter(Boolean).map((url: any, index) => {
    if (index === 0) {
      newData.thumbnailUrl = url;
    } else {
      const ExtraUrl = `extra${index}Url`;
      newData[ExtraUrl as KeyOf<RepairshopServerModel>] = url;
    }
  });
  newData = deleteKeyNullInValue(newData);

  return newData;
};

export const putRepairshopInfo = async (req: UpdateRepairshopQueryModel) => {
  const { body, thumbnailFile, thumbnailKey, additionalFileList } = req;

  const additionFileKeyList = additionalFileList?.map(
    (file: string | File) =>
      (typeof file !== "string" &&
        file?.type &&
        `${REPAIR_PREFIX_KEY}${uuidv4()}.${file.type.split("/")[1]}`) as string,
  );

  const fileList: [any, any][] = [
    [thumbnailKey, thumbnailFile],
    [additionFileKeyList[0], additionalFileList[0]],
    [additionFileKeyList[1], additionalFileList[1]],
    [additionFileKeyList[2], additionalFileList[2]],
    [additionFileKeyList[3], additionalFileList[3]],
  ];

  const postPresignedFileList = fileList
    .filter(([key, value]) => key && typeof value === "object")
    .map(async (array) => {
      const [s3Key, file]: [string, File] = [...array];
      return await postPresignedUrlAPI({
        s3Key,
        file,
      });
    });

  await Promise.all(postPresignedFileList);

  const fileData: [string, any][] = [
    [body?.thumbnail, thumbnailKey],
    [body?.extra1!, additionFileKeyList[0]],
    [body?.extra2!, additionFileKeyList[1]],
    [body?.extra3!, additionFileKeyList[2]],
    [body?.extra4!, additionFileKeyList[3]],
  ];

  const calcFileUrl = (data: string) => {
    if (!data || data === "default") return;

    if (data.includes(REPAIR_PREFIX_KEY)) {
      return data.replace(REPAIR_PREFIX_KEY, "");
    }
    return;
  };

  const fileBody = fileData.map(
    ([bodyData, keyData]) => calcFileUrl(bodyData) || calcFileUrl(keyData),
  );

  const newData = {
    ...req.body,
    ...(fileBody[0] && { thumbnail: fileBody[0] }),
    ...(fileBody[1] && { extra1: fileBody[1] }),
    ...(fileBody[2] && { extra2: fileBody[2] }),
    ...(fileBody[3] && { extra3: fileBody[3] }),
    ...(fileBody[4] && { extra4: fileBody[4] }),
  };

  await ax.put("", newData);
};
