import React, { useCallback, useMemo } from "react";

import { Button, PreviewModal } from "components";
import { useModal } from "hooks";
import { useDownloadFile, useDownloadZip } from "services";
import { getFileExtension } from "utils";
import { ATTACHED_FILE, DownloadIconImg } from "assets";
import * as S from "./AttachedFile.styled";

interface AttachedFileProps {
  data: any;
  handleParentModalInvisible?: () => void;
  handleParentModalVisible?: () => void;
}

const AttachedFile = ({
  data,
  handleParentModalInvisible,
  handleParentModalVisible,
}: AttachedFileProps) => {
  const { modalRef, handleModalOpen, handleModalClose } = useModal();

  const files = useMemo(
    () => [
      ...(data.presignedExternals ?? []),
      ...(data.presignedInternals ?? []),
      ...(data.presignedDashboard ? [data.presignedDashboard] : []),
    ],
    [data],
  );

  const attachFileObj = useMemo(
    () => ({
      images: [
        ...(data.presignedExternals
          ? data.presignedExternals.map((url: string, idx: number) => ({
              label: `외부 0${idx + 1}`,
              presignedUrl: url,
              file: data.externals[idx],
              extension: getFileExtension(data.externals[idx]),
              name: `${data.reservationNo}-외부0${idx + 1}`,
            }))
          : []),
        ...(data.presignedInternals
          ? data.presignedInternals.map((url: string, idx: number) => ({
              label: `내부 0${idx + 1}`,
              presignedUrl: url,
              file: data.internals[idx],
              extension: getFileExtension(data.internals[idx]),
              name: `${data.reservationNo}-내부0${idx + 1}`,
            }))
          : []),
        ...(data.presignedDashboard
          ? [
              {
                label: "계기판",
                presignedUrl: data.presignedDashboard,
                file: data.dashboard,
                extension: getFileExtension(data.dashboard),
                name: `${data.reservationNo}-계기판`,
              },
            ]
          : []),
      ],
      audio: {
        label: "녹음 파일",
        presignedUrl: data.presignedAudio,
        file: data.audio,
        extension: getFileExtension(data.audio),
        name: `${data.reservationNo}-녹음파일`,
      },
    }),
    [data],
  );

  const attachFiles = [
    ...attachFileObj.images,
    ...(attachFileObj.audio.file ? [attachFileObj.audio] : []),
  ];

  const { refetch: refetchDownloadZip } = useDownloadZip(attachFiles, {
    enabled: false,
  });
  const { refetch: refetchDownloadFile } = useDownloadFile(
    attachFileObj.audio,
    {
      enabled: false,
    },
  );

  const handleSelectFileIndex = (src: any) => () => {
    const index = Math.max(
      files.findIndex((file) => file === src),
      0,
    );

    handleModalOpen(
      <PreviewModal
        ref={modalRef}
        handleModalClose={handleModalClose}
        files={attachFileObj}
        selectedImgIndex={index}
        handleParentModalInvisible={handleParentModalInvisible}
        handleParentModalVisible={handleParentModalVisible}
      />,
    )();
  };

  const handleDownloadFile = useCallback(() => {
    refetchDownloadFile();
  }, [attachFileObj]);

  const handleDownloadZip = useCallback(() => {
    refetchDownloadZip();
  }, [attachFiles]);

  const images = [
    data.presignedAudio,
    data.presignedDashboard,
    data.presignedExternals,
    data.presignedInternals,
  ].flat();

  return (
    <>
      <S.Root>
        <S.InfoTitleItem>
          <span>첨부파일</span>
          {!images.filter((img) => !!img) && (
            <Button
              css={S.downloadButton}
              icon={<DownloadIconImg css={S.downloadIcon} />}
              variant="ghostPrimary"
              onClick={handleDownloadZip}
            >
              전체 다운로드
            </Button>
          )}
        </S.InfoTitleItem>
        {ATTACHED_FILE.map(
          ({ key, label, type }) =>
            data[key] && (
              <S.InfoItem key={key}>
                <dt>{label}</dt>
                <dd>
                  {typeof data[key] === "object" ? (
                    data[key].map((data: any, idx: number) => (
                      <Button
                        css={S.fileButton}
                        key={idx}
                        variant="ghostPrimary"
                        type="button"
                        onClick={handleSelectFileIndex(data)}
                      >{`${type} 0${idx + 1}`}</Button>
                    ))
                  ) : type === "음성" ? (
                    <Button
                      css={S.fileButton}
                      type="button"
                      variant="ghostPrimary"
                      onClick={handleDownloadFile}
                    >
                      {type}
                    </Button>
                  ) : (
                    <Button
                      css={S.fileButton}
                      type="button"
                      variant="ghostPrimary"
                      onClick={handleSelectFileIndex(data[key])}
                    >
                      {type}
                    </Button>
                  )}
                </dd>
              </S.InfoItem>
            ),
        )}
      </S.Root>
    </>
  );
};

export default AttachedFile;
