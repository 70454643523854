import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.dl`
  border-top: 1px solid ${({ theme }) => theme.color.gray_03};
  padding: 16px 0;
`;

export const InfoTitleItem = styled.div`
  ${({ theme }) => css`
    ${theme.font.medium_14};
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    & > span {
      color: ${theme.color.gray_08};
    }
  `}
`;

export const InfoItem = styled.div`
  ${({ theme }) => css`
    ${theme.font.regular_14};
    display: grid;
    grid-template-columns: 132px 1fr;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & > dt {
      color: ${theme.color.gray_07};
    }

    & > dd {
      display: flex;
      color: ${theme.color.gray_10};
    }

    &:first-of-type {
      & > dd {
        display: flex;
        justify-content: flex-end;
      }
    }
  `}
`;

export const fileButton = css`
  &:not(:first-of-type) {
    margin-left: 12px;
  }
`;

export const downloadButton = (theme: Theme) => css`
  &:hover path:not(:first-of-type) {
    fill: ${theme.color.blue_03}!important;
  }
`;

export const downloadIcon = css`
  margin-right: 6px;
`;
